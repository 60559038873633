<template>
  <div class="change_bg">

    <div class="modal_bg" :class="{'active' : isActive_m}">
      <div class="modal_content">
        <div class="m_card">
          <div class="m_card_main">
            <div class="m_body_card_main">

            </div>
            <div>
              <button class="exit_modal" @click="isActive_m=false">
                {{$store.state.language.lang[73]}}
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.03125 0.861328L15.2526 15.08M1.03125 15.08L15.2526 0.861328" stroke="#1DBB95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="m_card_bottom">
            <div class="m_b">
              <div class="m_b_data">{{dataitem.obligation}}</div>
            </div>
            <div class="m_b">
              <div class="m_b_data">{{dataitem.requirement}}</div>
            </div>
            <div class="m_b">
              <div class="m_b_data">{{dataitem.work_condition}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">  {{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[183]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class=" col-12">
            <div class="m_body_title">
              <h2>  {{$store.state.language.lang[183]}}</h2>
            </div>
          <div class="opencard">
            <div class="child_data "  v-for="(item,index) in $store.state.opendata.results" :key="index">
              <div>
                <router-link :to="'/opendata/'+item.id" class="links">
                  <div class="text_data">
                    {{item.title}}
                  </div>
                </router-link>
              </div>
              <div class="icon_data d-flex">
                <a :href="item.file" target="_blank" class="btn">
                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.084 14.667C22.084 14.2528 21.7482 13.917 21.334 13.917C20.9198 13.917 20.584 14.2528 20.584 14.667H22.084ZM3.41797 14.667C3.41797 14.2528 3.08218 13.917 2.66797 13.917C2.25376 13.917 1.91797 14.2528 1.91797 14.667H3.41797ZM18.2793 10.8073C18.5722 10.5144 18.5722 10.0396 18.2793 9.74667C17.9864 9.45378 17.5115 9.45378 17.2186 9.74667L18.2793 10.8073ZM12.036 15.99L11.5056 16.5203C11.6463 16.661 11.8371 16.74 12.036 16.74C12.2349 16.74 12.4256 16.661 12.5663 16.5203L12.036 15.99ZM6.8533 9.74667C6.56041 9.45378 6.08553 9.45378 5.79264 9.74667C5.49975 10.0396 5.49975 10.5144 5.79264 10.8073L6.8533 9.74667ZM12.786 2.677C12.786 2.26279 12.4502 1.927 12.036 1.927C11.6218 1.927 11.286 2.26279 11.286 2.677H12.786ZM11.286 15.973C11.286 16.3872 11.6218 16.723 12.036 16.723C12.4502 16.723 12.786 16.3872 12.786 15.973H11.286ZM20.584 14.667V17.714H22.084V14.667H20.584ZM20.584 17.714C20.584 18.9568 19.5768 19.964 18.334 19.964V21.464C20.4052 21.464 22.084 19.7852 22.084 17.714H20.584ZM18.334 19.964H5.66797V21.464H18.334V19.964ZM5.66797 19.964C4.42518 19.964 3.41797 18.9568 3.41797 17.714H1.91797C1.91797 19.7852 3.59675 21.464 5.66797 21.464V19.964ZM3.41797 17.714V14.667H1.91797V17.714H3.41797ZM17.2186 9.74667L11.5056 15.4597L12.5663 16.5203L18.2793 10.8073L17.2186 9.74667ZM12.5663 15.4597L6.8533 9.74667L5.79264 10.8073L11.5056 16.5203L12.5663 15.4597ZM11.286 2.677V15.973H12.786V2.677H11.286Z" fill="#00B57F"/>
                  </svg>
                </a>

                <a :href="item.url" target="_blank" class="btn">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.8251 3.34717C11.4109 3.34717 11.0751 3.68295 11.0751 4.09717C11.0751 4.51138 11.4109 4.84717 11.8251 4.84717V3.34717ZM19.9051 4.09717H20.6551C20.6551 3.68295 20.3193 3.34717 19.9051 3.34717V4.09717ZM19.1551 12.1762C19.1551 12.5904 19.4909 12.9262 19.9051 12.9262C20.3193 12.9262 20.6551 12.5904 20.6551 12.1762H19.1551ZM9.95979 12.9808C9.66688 13.2737 9.66686 13.7486 9.95974 14.0415C10.2526 14.3344 10.7275 14.3344 11.0204 14.0415L9.95979 12.9808ZM20.4234 4.63953C20.7163 4.34665 20.7163 3.87177 20.4235 3.57887C20.1306 3.28596 19.6557 3.28593 19.3628 3.57881L20.4234 4.63953ZM9.58409 8.09717C9.99831 8.09717 10.3341 7.76138 10.3341 7.34717C10.3341 6.93295 9.99831 6.59717 9.58409 6.59717V8.09717ZM17.4041 14.4162C17.4041 14.002 17.0683 13.6662 16.6541 13.6662C16.2399 13.6662 15.9041 14.002 15.9041 14.4162H17.4041ZM11.8251 4.84717H19.9051V3.34717H11.8251V4.84717ZM19.1551 4.09717V12.1762H20.6551V4.09717H19.1551ZM11.0204 14.0415L20.4234 4.63953L19.3628 3.57881L9.95979 12.9808L11.0204 14.0415ZM9.58409 6.59717H7.52209V8.09717H9.58409V6.59717ZM7.52209 6.59717C5.64388 6.59717 4.12109 8.11995 4.12109 9.99817H5.62109C5.62109 8.94838 6.47231 8.09717 7.52209 8.09717V6.59717ZM4.12109 9.99817V16.4792H5.62109V9.99817H4.12109ZM4.12109 16.4792C4.12109 18.3574 5.64388 19.8802 7.52209 19.8802V18.3802C6.47231 18.3802 5.62109 17.529 5.62109 16.4792H4.12109ZM7.52209 19.8802H14.0031V18.3802H7.52209V19.8802ZM14.0031 19.8802C15.8813 19.8802 17.4041 18.3574 17.4041 16.4792H15.9041C15.9041 17.529 15.0529 18.3802 14.0031 18.3802V19.8802ZM17.4041 16.4792V14.4162H15.9041V16.4792H17.4041Z" fill="#00B57F"/>
                </svg>
              </a>

              </div>
            </div>
          </div>
            <b-pagination
                v-model="page"
                :total-rows="$store.state.opendata.total_pages"
                :per-page="1"

                aria-controls="my-table"></b-pagination>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Header/Footer";

export default {
  components: {
    Footer,
    Navbar
  },
  data() {
    return {
      isActive_m: false,
      dataitem:{},
      page: 1,
      menu:'',
      currentPage: 1,
    }
  },
  mounted() {
    this.$store.dispatch('opendata',1)
  },
  methods: {
    open_modal() {

    },
    myFunck(item=item){
      this.dataitem=item
    }
  },
  watch: {
    page(page) {
      this.$store.dispatch('opendata', page)
    }
  },
}
</script>
